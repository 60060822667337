import { SimpleDataset } from 'services/MetricsService';
import {
  changeMargins,
  generateTickData,
  generateTickIndicies,
  maxHeight,
  maxWidth,
  usualMargins,
} from './utils';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { filesize } from 'filesize';
import { MSDTooltip } from './MSDTooltip';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import ChartDataLabel from './ChartDataLabel';
import { CircularProgress } from '@mui/material';

export interface LineChartProps {
  data: SimpleDataset;
  total: string | number | undefined;
  metricType: string;
  tooltipVar: string;
  tooltipFormatter?: boolean;
  widthPercent?: number;
  marginsChanges?: Record<string, number>;
  filesizeFormat?: boolean;
}

const Root = styled.div({});

const StyledSpinner = styled(CircularProgress)({
  color: '#16adfe',
});

function MSDLineChart(props: LineChartProps): React.ReactElement {
  const {
    data,
    total,
    metricType,
    widthPercent,
    marginsChanges,
    filesizeFormat,
    tooltipVar,
    tooltipFormatter,
  } = props;
  const chartMargins = marginsChanges
    ? changeMargins(marginsChanges)
    : usualMargins;

  if (!data) {
    return <StyledSpinner size="4rem" />;
  }

  const tickIndicies = generateTickIndicies(data.length);
  const tickData = generateTickData(data, tickIndicies);

  return (
    <Root>
      <ChartDataLabel data={total} name={metricType} />
      <LineChart
        width={widthPercent ? maxWidth * widthPercent : maxWidth}
        height={maxHeight}
        data={data}
        margin={chartMargins}
        {...{ overflow: 'visible' }}
      >
        <XAxis
          dataKey="day"
          stroke="#093e80"
          minTickGap={48}
          ticks={tickData}
        />
        <YAxis
          dataKey="Value"
          stroke="#093e80"
          tickFormatter={filesizeFormat ? filesize : undefined}
        />
        <CartesianGrid horizontal vertical={false} strokeDasharray="3 3" />
        <Line
          type="monotone"
          dataKey="Value"
          stroke="#16adfe"
          strokeWidth={2}
          dot={false}
        />
        <Tooltip
          position={{ x: 70, y: 10 }}
          content={
            <MSDTooltip
              varName={tooltipVar}
              varFormatter={tooltipFormatter || false}
              esPluralize={['Launch'].includes(tooltipVar)}
              noOpeningName
            />
          }
        />
      </LineChart>
    </Root>
  );
}

export default observer(MSDLineChart);
